import { LoadingButton } from '@mui/lab';
import { Button, Grid } from '@mui/material';

interface Props {
  activeStep: number;
  isSubmitting: boolean;
  nextDisabled: boolean;
  onChangeStep: (step: number) => void;
  onClose: VoidFunction;
  onSubmit: VoidFunction;
}

export default function StepperNavigator({
  activeStep,
  nextDisabled,
  onChangeStep,
  onClose,
  onSubmit,
  isSubmitting,
}: Props) {
  return (
    <Grid container justifyContent="space-between" sx={{ my: 2 }}>
      <Grid item xs={2} textAlign="center">
        {activeStep > 0 ? (
          <Button color="secondary" variant="outlined" onClick={() => onChangeStep(activeStep - 1)}>
            Anterior
          </Button>
        ) : null}
      </Grid>
      <Grid item xs={2} textAlign="center">
        <Button variant="text" color="error" onClick={onClose}>
          Fechar
        </Button>
      </Grid>
      <Grid item xs={2} textAlign="center">
        {activeStep < 2 ? (
          <Button
            disabled={nextDisabled}
            color="primary"
            variant="outlined"
            onClick={() => onChangeStep(activeStep + 1)}
          >
            Próximo
          </Button>
        ) : (
          <LoadingButton
            disabled={nextDisabled}
            onClick={onSubmit}
            variant="contained"
            loading={isSubmitting}
            color="primary"
          >
            Enviar alterações
          </LoadingButton>
        )}
      </Grid>
    </Grid>
  );
}
