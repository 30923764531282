import Iconify from '@/components/Iconify';
import {
  APP_REFRESH_ICON,
  RMS_ABORT_ICON,
  RMS_APPROVE_ICON,
  RMS_RESTORE_ICON,
} from '@/config/icons';
import { useNotification } from '@/hooks/useNotification';
import { useAppDispatch } from '@/redux';
import { openRmsFlutuationDetails } from '@/redux/slices/rmsSlice';

import { FluctuationStatus } from '@/types/enums/rms/fluctuation-status.enum';
import { Fluctuation } from '@/types/models/fluctuation';
import { apiV1RmsService } from '@/utils/api';
import { formatDate } from '@/utils/dates';
import { formatCurrency } from '@/utils/number';
import {
  getFluctuationDetailsCardIcon,
  getFluctuationDetailsCardSubTitle,
  getFluctuationDetailsCardTitle,
} from '@/utils/rms/fluctuation';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Container,
  Divider,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';

interface Props {
  fluctuation: Fluctuation;
}

export default function FluctuationDecisionContainer({ fluctuation }: Props) {
  const { showSuccess } = useNotification();
  const [submiting, setSetsubmiting] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!fluctuation?._id) {
      setSetsubmiting(false);
    }
  }, [fluctuation?._id]);

  const onSubmitApproved = async () => {
    try {
      const answer = window.confirm('Tem certeza?');
      if (!answer) return;
      setSetsubmiting(true);
      const params = {
        fluctuationIds: [fluctuation?._id],
        status: FluctuationStatus.QUEUED,
      };
      await apiV1RmsService.put('/fluctuations/update-status', params);
      showSuccess('Flutuação aprovada com sucesso!', 6e3);
      dispatch(openRmsFlutuationDetails(fluctuation._id)); // Reload data
    } catch {
      //
    }
    setSetsubmiting(false);
  };

  const onSubmitDisable = async () => {
    try {
      const answer = window.confirm(
        `Tem certeza? Todas as sugestões para o dia ${formatDate(
          fluctuation?.date,
          'dd/MM/yyyy',
        )} serão desabilitadas permanentemente.`,
      );
      if (!answer) return;
      setSetsubmiting(true);
      const params = {
        fluctuationIds: [fluctuation?._id],
        status: FluctuationStatus.DISABLED,
      };
      await apiV1RmsService.put('/fluctuations/update-status', params);
      showSuccess('Flutuação desabilitada permanentemente!', 5e3);
      dispatch(openRmsFlutuationDetails(fluctuation._id)); // Reload data
    } catch {
      //
    }
    setSetsubmiting(false);
  };

  /**
   * Retorna para status DECISION_PENDING
   */
  const onSubmitRestore = async () => {
    try {
      const answer = window.confirm(`Tem certeza?`);
      if (!answer) return;
      setSetsubmiting(true);
      const params = {
        fluctuationIds: [fluctuation?._id],
        status: FluctuationStatus.DECISION_PENDING,
      };
      await apiV1RmsService.put('/fluctuations/update-status', params);
      showSuccess('Flutuação reativada!', 5e3);
      dispatch(openRmsFlutuationDetails(fluctuation._id)); // Reload data
    } catch {
      //
    }
    setSetsubmiting(false);
  };

  if (submiting || !fluctuation?._id) {
    return (
      <Container>
        <Box sx={{ textAlign: 'center', alignItems: 'center', mt: 3 }}>
          <Typography variant="h4" paragraph>
            Aguarde...
          </Typography>
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  return (
    <Card elevation={3} sx={{ py: 1, mt: 3 }}>
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: 'primary' }} aria-label="recipe">
            <Iconify icon={getFluctuationDetailsCardIcon(fluctuation)} width={30} height={30} />
          </Avatar>
        }
        title={getFluctuationDetailsCardTitle(fluctuation)}
        subheader={getFluctuationDetailsCardSubTitle(fluctuation)}
        sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)', paddingBottom: 2 }}
      />
      <CardContent>
        <Paper sx={{ width: '100%' }}>
          <Stack
            alignItems="center"
            justifyContent="center"
            direction="row"
            divider={<Divider orientation="vertical" flexItem />}
            spacing={3}
          >
            {fluctuation.status === FluctuationStatus.QUEUED ? (
              <Tooltip
                arrow
                title={`Cancelar execução de atualização de tarifas. Os últimos valores aplicados na Omnibees (${formatCurrency(
                  fluctuation?.appliedPrice!,
                )}) serão mantidos.`}
              >
                <Button
                  color="error"
                  variant="outlined"
                  startIcon={<Iconify icon={RMS_ABORT_ICON} width={30} height={30} />}
                  onClick={onSubmitRestore}
                >
                  Cancelar execução
                </Button>
              </Tooltip>
            ) : null}
            {fluctuation.status === FluctuationStatus.DISABLED ? (
              <Tooltip arrow title="Restaurar sugestões">
                <Button
                  color="primary"
                  variant="outlined"
                  startIcon={<Iconify icon={RMS_RESTORE_ICON} width={30} height={30} />}
                  onClick={onSubmitRestore}
                >
                  Reativar sugestões
                </Button>
              </Tooltip>
            ) : null}
            {fluctuation.status === FluctuationStatus.DECISION_PENDING ? (
              <>
                <Tooltip arrow title="O valor será aplicado na próxima execução do robô.">
                  <Button
                    color="primary"
                    variant="outlined"
                    startIcon={<Iconify icon={RMS_APPROVE_ICON} width={30} height={30} />}
                    onClick={onSubmitApproved}
                  >
                    <span>Aprovar valores</span>
                  </Button>
                </Tooltip>

                <Tooltip
                  arrow
                  title={`A sugestão atual e todas as futuras sugestões serão ignoradas e o último valor aplicado na Omnibees (${formatCurrency(
                    fluctuation?.appliedPrice!,
                  )}) será mantido.`}
                >
                  <Button
                    color="error"
                    variant="outlined"
                    startIcon={<Iconify icon={RMS_ABORT_ICON} width={30} height={30} />}
                    onClick={onSubmitDisable}
                  >
                    Desabilitar sugestões
                  </Button>
                </Tooltip>
              </>
            ) : null}
            {fluctuation.status === FluctuationStatus.ERROR ? (
              <Container>
                <Box sx={{ textAlign: 'center', alignItems: 'center', mt: 3 }}>
                  <Typography variant="h4" paragraph>
                    Erro de processamento
                  </Typography>

                  <Typography sx={{ color: 'text.secondary' }}>
                    Erro de integração com a Omnibees.
                  </Typography>
                </Box>
              </Container>
            ) : null}
            {fluctuation.status === FluctuationStatus.OMNIBEES_DISABLED ? (
              <Container>
                <Box sx={{ textAlign: 'center', alignItems: 'center', mt: 3 }}>
                  <Typography variant="h4" paragraph>
                    Omnibees desabilitado
                  </Typography>

                  <Typography sx={{ color: 'text.secondary' }}>
                    Hotel não possui integração ativa com a Omnibees.
                  </Typography>
                </Box>
              </Container>
            ) : null}
            {fluctuation.status === FluctuationStatus.OMNIBEES_RATE_PLAN_UNLINKED ? (
              <Container>
                <Box sx={{ textAlign: 'center', alignItems: 'center', mt: 3 }}>
                  <Typography variant="h4" paragraph>
                    Plano tarifário não vinculado
                  </Typography>

                  <Typography sx={{ color: 'text.secondary' }}>
                    Plano tarifário não possui link com a tarifa Omnibees.
                  </Typography>
                </Box>
              </Container>
            ) : null}
            {fluctuation.status === FluctuationStatus.OMNIBEES_ROOM_TYPE_UNLINKED ? (
              <Container>
                <Box sx={{ textAlign: 'center', alignItems: 'center', mt: 3 }}>
                  <Typography variant="h4" paragraph>
                    Acomodação não vinculada
                  </Typography>

                  <Typography sx={{ color: 'text.secondary' }}>
                    Acomodação não possui link com a acomodação Omnibees.
                  </Typography>
                </Box>
              </Container>
            ) : null}

            {fluctuation.status === FluctuationStatus.UPDATED ? (
              <Container>
                <Box sx={{ textAlign: 'center', alignItems: 'center', mt: 3 }}>
                  <Typography variant="h4" paragraph>
                    Sugestões aplicadas
                  </Typography>

                  <Typography sx={{ color: 'text.secondary' }}>
                    As sugestões foram aplicadas com sucesso em{' '}
                    {formatDate(fluctuation.appliedPricesAt)}.
                  </Typography>
                </Box>
                <Box sx={{ textAlign: 'center', alignItems: 'center', mt: 3 }}>
                  <Tooltip arrow title="O valor será aplicado na próxima execução do robô.">
                    <Button
                      color="primary"
                      variant="outlined"
                      startIcon={<Iconify icon={APP_REFRESH_ICON} width={30} height={30} />}
                      onClick={onSubmitApproved}
                    >
                      <span>Reprocessar valores</span>
                    </Button>
                  </Tooltip>
                </Box>
              </Container>
            ) : null}

            {fluctuation.status === FluctuationStatus.OMNIBEES_INCOMPATIBLE_ROOM_OR_RATE ? (
              <Container>
                <Box sx={{ textAlign: 'center', alignItems: 'center', mt: 3 }}>
                  <Typography variant="h4" paragraph>
                    Acomodação e tarifa incompatíveis
                  </Typography>

                  <Typography sx={{ color: 'text.secondary' }}>
                    A acomodação e a tarifa da sugestão não possuem compatibilidade com a Omnibees.
                  </Typography>
                </Box>
              </Container>
            ) : null}
          </Stack>
        </Paper>
      </CardContent>
    </Card>
  );
}
