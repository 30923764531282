import { SavedSearch } from '@/types/models/saved-search';
import { UserSession } from '@/types/models/user-session';

export function hasPermissionToEditSavedSearch(session: UserSession, obj: SavedSearch) {
  try {
    let hasPermission = false;
    if (obj.owner._id === session.user._id) {
      hasPermission = true;
    } else {
      hasPermission =
        obj.users.some((x) => session.user._id === x._id && x.permission === 'write') ||
        obj.wallets.some(
          (x) => session.user.wallets.some((y) => y._id === x._id) && x.permission === 'write',
        );
    }
    return hasPermission;
  } catch (error) {
    console.log(`hasPermissionToEditSavedSearch LOG:  error:`, error);
    return false;
  }
}
