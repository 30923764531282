import Iconify from '@/components/Iconify';
import Scrollbar from '@/components/Scrollbar';
import { varFade } from '@/components/animate/variants/fade';
import { NAVBAR } from '@/config/layout';
import { useResponsive } from '@/hooks/useResponsive';
import { useThemeSettings } from '@/hooks/useThemeSettings';
import { cssStyles } from '@/utils/css-styles';
import { Backdrop, Divider, IconButton, Stack, Typography } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { AnimatePresence, m } from 'framer-motion';
import { useEffect } from 'react';
import SettingContrast from './SettingContrast';
import SettingFullscreen from './SettingFullscreen';
import SettingLayout from './SettingLayout';
import SettingMode from './SettingMode';
import SettingStretch from './SettingStretch';

const RootStyle = styled(m.div)(({ theme }) => ({
  ...cssStyles(theme).bgBlur({ color: theme.palette.background.paper, opacity: 0.92 }),
  top: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  position: 'fixed',
  overflow: 'hidden',
  width: NAVBAR.BASE_WIDTH,
  flexDirection: 'column',
  margin: theme.spacing(2),
  paddingBottom: theme.spacing(3),
  zIndex: theme.zIndex.drawer + 3,
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  boxShadow: `-24px 12px 32px -4px ${alpha(
    theme.palette.mode === 'light' ? theme.palette.grey[500] : theme.palette.common.black,
    0.16,
  )}`,
}));

export default function ThemeDrawer() {
  const { themeMode, onResetSetting, drawerOpen, onToggleDrawer } = useThemeSettings();
  const isDesktop = useResponsive('up', 'lg');
  const isLarge = useResponsive('up', 'xl');

  const varSidebar = varFade({
    distance: NAVBAR.BASE_WIDTH,
    durationIn: 0.32,
    durationOut: 0.32,
  }).inRight;

  useEffect(() => {
    if (drawerOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  }, [drawerOpen]);

  const handleClose = () => {
    onToggleDrawer();
  };

  return (
    <>
      <Backdrop
        open={drawerOpen}
        onClick={handleClose}
        sx={{ background: 'transparent', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      />

      <AnimatePresence>
        {drawerOpen && (
          <>
            <RootStyle {...varSidebar}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ py: 2, pr: 1, pl: 2.5 }}
              >
                <Typography variant="subtitle1" sx={{ flexGrow: 1 }}>
                  Configurações
                </Typography>

                <IconButton onClick={onResetSetting}>
                  <Iconify icon={'ic:round-refresh'} width={20} height={20} />
                </IconButton>

                <IconButton onClick={handleClose}>
                  <Iconify icon={'eva:close-fill'} width={20} height={20} />
                </IconButton>
              </Stack>

              <Divider sx={{ borderStyle: 'dashed' }} />

              <Scrollbar sx={{ flexGrow: 1 }}>
                <Stack spacing={3} sx={{ p: 3 }}>
                  <Stack spacing={1.5}>
                    <Typography variant="subtitle2">Modo</Typography>
                    <SettingMode />
                  </Stack>

                  {themeMode !== 'dark' && (
                    <Stack spacing={1.5}>
                      <Typography variant="subtitle2">Contraste</Typography>
                      <SettingContrast />
                    </Stack>
                  )}

                  {isDesktop && (
                    <Stack spacing={1.5}>
                      <Typography variant="subtitle2">Layout</Typography>
                      <SettingLayout />
                    </Stack>
                  )}

                  {isLarge && (
                    <Stack spacing={1.5}>
                      <Typography variant="subtitle2">Esticar</Typography>
                      <SettingStretch />
                    </Stack>
                  )}

                  <SettingFullscreen />
                </Stack>
              </Scrollbar>
            </RootStyle>
          </>
        )}
      </AnimatePresence>
    </>
  );
}
