import { Box } from '@mui/material';

export default function RoomTypeCategoriesColorLegend() {
  return (
    <>
      <Box
        sx={{ p: 0.7, m: 1, borderRadius: 0.5, fontSize: 12 }}
        className="room-type-category-hotel-default"
      >
        Padrão do hotel
      </Box>
      <Box
        sx={{ p: 0.7, m: 1, borderRadius: 0.5, fontSize: 12 }}
        className="room-type-category-default"
      >
        Padrão da acomodação
      </Box>
    </>
  );
}
