import Iconify from '@/components/Iconify';
import IconButtonAnimate from '@/components/animate/IconButtonAnimate';
import { useThemeSettings } from '@/hooks/useThemeSettings';
import { useAppDispatch } from '@/redux';
import { toggleSavedSearchDrawerOpened } from '@/redux/slices/filtersSlice';
import { cssStyles } from '@/utils/css-styles';
import { Tooltip } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';

const RootStyle = styled('span')(({ theme }) => ({
  ...cssStyles(theme).bgBlur({ opacity: 0.64 }),
  right: 0,
  top: '50%',
  position: 'fixed',
  marginTop: theme.spacing(-3),
  padding: theme.spacing(0.5),
  zIndex: theme.zIndex.drawer + 2,
  borderRadius: '24px 0 20px 24px',
  boxShadow: `-12px 12px 32px -4px ${alpha(
    theme.palette.mode === 'light' ? theme.palette.grey[600] : theme.palette.common.black,
    0.36,
  )}`,
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1), // Espaçamento entre os botões
}));

export default function DrawersToggleButton() {
  const { onToggleDrawer } = useThemeSettings();
  const dispatch = useAppDispatch();

  return (
    <RootStyle>
      <Tooltip arrow title="Filtros salvos" placement="left">
        <IconButtonAnimate
          color="inherit"
          onClick={() => dispatch(toggleSavedSearchDrawerOpened())}
          sx={{
            p: 1.25,
            transition: (theme) => theme.transitions.create('all'),
            '&:hover': {
              color: 'secondary.main',
              bgcolor: (theme) =>
                alpha(theme.palette.secondary.main, theme.palette.action.hoverOpacity),
            },
          }}
        >
          <Iconify icon="tabler:filter-cog" width={20} height={20} />
        </IconButtonAnimate>
      </Tooltip>

      <Tooltip arrow title="Configurações" placement="left">
        <IconButtonAnimate
          color="inherit"
          onClick={() => onToggleDrawer()}
          sx={{
            p: 1.25,
            transition: (theme) => theme.transitions.create('all'),
            '&:hover': {
              color: 'primary.main',
              bgcolor: (theme) =>
                alpha(theme.palette.primary.main, theme.palette.action.hoverOpacity),
            },
          }}
        >
          <Iconify icon="eva:options-2-fill" width={20} height={20} />
        </IconButtonAnimate>
      </Tooltip>
    </RootStyle>
  );
}
