import { RootState } from '@/redux';
import { Fluctuation } from '@/types/models/fluctuation';
import { FluctuationLog } from '@/types/models/fluctuation-log';
import { GriCategory } from '@/types/models/gri-category';
import { RatePlan } from '@/types/models/rate-plan';
import { RoomType } from '@/types/models/room-type';
import { apiV1CrudsService, apiV1RmsService } from '@/utils/api';
import { formatDateApi } from '@/utils/dates';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { stringify } from 'query-string';

type FluctuationDetailsData = {
  fluctuation: Fluctuation;
};

type FluctuationConfigOptions = {
  selectedDates: Date[];
  selectedRatePlans: RatePlan[];
  selectedRoomTypes: RoomType[];
  selectedFluctuations: Fluctuation[];
};

type RmsSliceState = {
  fluctuations: Fluctuation[];
  fluctuationsLoading: boolean;
  fluctuationDetailsModalOpened: FluctuationDetailsData | null;
  fluctuationLogDetailsModalOpened: FluctuationLog | null;
  fluctuationConfigOptions: FluctuationConfigOptions;
  calendarRows: Date[][];
  griCategories: GriCategory[];
};

const initialState: RmsSliceState = {
  fluctuations: [],
  fluctuationsLoading: false,
  fluctuationDetailsModalOpened: null,
  fluctuationLogDetailsModalOpened: null,
  fluctuationConfigOptions: {
    selectedDates: [],
    selectedRatePlans: [],
    selectedRoomTypes: [],
    selectedFluctuations: [],
  },
  calendarRows: [],
  griCategories: [],
};

const rmsSlice = createSlice({
  name: 'rms',
  initialState,
  reducers: {
    closeFluctuationDetails(state) {
      state.fluctuationDetailsModalOpened = null;
    },
    setFluctuationLogDetailsOpened(state, action: PayloadAction<FluctuationLog | null>) {
      state.fluctuationLogDetailsModalOpened = action.payload;
    },
    setFluctuationConfigOptions(state, action: PayloadAction<FluctuationConfigOptions>) {
      state.fluctuationConfigOptions = action.payload;
    },
    setFluctuationsCalendarRows(state, action: PayloadAction<Date[][]>) {
      state.calendarRows = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRmsFlutuations.pending, (state) => {
        state.fluctuationsLoading = true;
        state.fluctuations = [];
      })
      .addCase(fetchRmsFlutuations.fulfilled, (state, action: PayloadAction<Fluctuation[]>) => {
        state.fluctuationsLoading = false;
        state.fluctuations = action.payload;
      })
      .addCase(fetchRmsFlutuations.rejected, (state, action) => {
        state.fluctuationsLoading = false;
        state.fluctuations = [];
      })
      .addCase(fetchRmsFlutuationsCalendar.pending, (state) => {
        state.fluctuationsLoading = true;
        state.fluctuations = [];
      })
      .addCase(
        fetchRmsFlutuationsCalendar.fulfilled,
        (state, action: PayloadAction<Fluctuation[]>) => {
          state.fluctuationsLoading = false;
          state.fluctuations = action.payload;
        },
      )
      .addCase(fetchRmsFlutuationsCalendar.rejected, (state, action) => {
        state.fluctuationsLoading = false;
        state.fluctuations = [];
      })
      .addCase(fetchRmsGriCategories.pending, (state) => {
        state.griCategories = [];
      })
      .addCase(fetchRmsGriCategories.fulfilled, (state, action: PayloadAction<GriCategory[]>) => {
        state.griCategories = action.payload;
      })
      .addCase(fetchRmsGriCategories.rejected, (state, action) => {
        state.griCategories = [];
      })
      .addCase(
        openRmsFlutuationDetails.fulfilled,
        (state, action: PayloadAction<FluctuationDetailsData>) => {
          state.fluctuationDetailsModalOpened = action.payload;
        },
      )
      .addCase(openRmsFlutuationDetails.rejected, (state) => {
        state.fluctuationDetailsModalOpened = null;
      });
  },
});

export const fetchRmsFlutuations = createAsyncThunk<Fluctuation[], void, { state: RootState }>(
  'rms/fetchRmsFlutuations',
  async (_, { rejectWithValue, getState }) => {
    try {
      const { commonFilters, rmsFilters } = getState().filters;
      const { startDate, endDate } = commonFilters;
      const {
        ratePlans,
        roomTypes,
        status,
        fluctuationRules,
        usingCustomPrice,
        startDateSubmission,
        endDateSubmission,
      } = rmsFilters;
      const params: any = {
        startDate: formatDateApi(startDate),
        endDate: formatDateApi(endDate),
        startDateSubmission: formatDateApi(startDateSubmission),
        endDateSubmission: formatDateApi(endDateSubmission),
        ratePlans: ratePlans.map((rateplan) => rateplan._id),
        roomTypes: roomTypes.map((roomType) => roomType._id),
        fluctuationRules: fluctuationRules.map((fluctuationRule) => fluctuationRule._id),
      };

      if (!!status && status !== 'ALL') {
        params.status = status;
      }

      params.roomTypes = roomTypes?.map((x) => x._id);

      if (usingCustomPrice) params.usingCustomPrice = true;

      const response = await apiV1RmsService.get<Fluctuation[]>(
        `/fluctuations/list?${stringify(params, { arrayFormat: 'bracket' })}`,
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const fetchRmsFlutuationsCalendar = createAsyncThunk<
  Fluctuation[],
  void,
  { state: RootState }
>('rms/fetchRmsFlutuationsCalendar', async (_, { rejectWithValue, getState }) => {
  try {
    const { commonFilters, rmsFilters } = getState().filters;
    const { startDate, endDate } = commonFilters;
    const { ratePlan, roomType, status, fluctuationRule, usingCustomPrice } = rmsFilters;
    const params: any = {
      startDate: formatDateApi(startDate),
      endDate: formatDateApi(endDate),
      roomType: roomType?._id,
      ratePlan: ratePlan?._id,
      fluctuationRule: fluctuationRule?._id,
    };

    if (!!status && status !== 'ALL') {
      params.status = status;
    }

    if (usingCustomPrice) {
      params.usingCustomPrice = true;
    }

    const response = await apiV1RmsService.get<Fluctuation[]>(
      `/fluctuations/calendar?${stringify(params, { arrayFormat: 'bracket' })}`,
    );

    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const openRmsFlutuationDetails = createAsyncThunk<
  FluctuationDetailsData,
  string,
  { state: RootState }
>('rms/openRmsFlutuationDetails', async (fluctuationId, { rejectWithValue, getState }) => {
  try {
    const response = await apiV1RmsService.get<FluctuationDetailsData>(
      `/fluctuations/details/${fluctuationId}`,
    );

    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const fetchRmsGriCategories = createAsyncThunk<GriCategory[], void, { state: RootState }>(
  'rms/fetchRmsGriCategories',
  async (_, { rejectWithValue, getState }) => {
    try {
      const response = await apiV1CrudsService.get<GriCategory[]>(`/gri-categories/all`);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const {
  setFluctuationLogDetailsOpened,
  closeFluctuationDetails,
  setFluctuationConfigOptions,
  setFluctuationsCalendarRows,
} = rmsSlice.actions;
export default rmsSlice.reducer;
