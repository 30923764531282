import { GenericIdName } from '../models/generic-id-name';

export enum AppPage {
  HOME = 'HOME',
  HOME_V2 = 'HOME_V2',
  RANKING = 'RANKING',
  CONCURRENCY = 'CONCURRENCY',
  BENCHMARK = 'BENCHMARK',
  PICKUP_HOTELS = 'PICKUP_HOTELS',
  PICKUP_SEGMENTS = 'PICKUP_SEGMENTS',
  PICKUP_CHANNELS = 'PICKUP_CHANNELS',
  PICKUP_V2 = 'PICKUP_V2',
  PICKUP_V2_RITM = 'PICKUP_V2_RITM',
  EMISSIONS_HOTELS = 'EMISSIONS_HOTELS',
  EMISSIONS_SEGMENTS = 'EMISSIONS_SEGMENTS',
  EMISSIONS_CHANNELS = 'EMISSIONS_CHANNELS',
  RMS_FLUCTUATIONS = 'RMS_FLUCTUATIONS',
  RMS_FLUCTUATIONS_CALENDAR = 'RMS_FLUCTUATIONS_CALENDAR',
  RMS_FLUCTUATIONS_RULES = 'RMS_FLUCTUATIONS_RULES',
  CLEANING_MANAGEMENT = 'CLEANING_MANAGEMENT',
  GOALS = 'GOALS',
  UNKNOWN = 'UNKNOWN',
  GRI = 'GRI',
}

/**
 * @description Obtém o valor do enum human readable
 */
export function getAppPageHumanRead(page: AppPage) {
  switch (page) {
    case AppPage.HOME:
      return 'Home';
    case AppPage.HOME_V2:
      return 'Home (V2)';
    case AppPage.RANKING:
      return 'Ranking';
    case AppPage.CONCURRENCY:
      return 'Concorrência';
    case AppPage.BENCHMARK:
      return 'Comparativos';
    case AppPage.PICKUP_HOTELS:
      return 'Pick-Up V1 (hotéis)';
    case AppPage.PICKUP_SEGMENTS:
      return 'Pick-Up V1 (segmentos)';
    case AppPage.PICKUP_CHANNELS:
      return 'Pick-Up V1 (canais)';
    case AppPage.PICKUP_V2:
      return 'Pick-Up V2';
    case AppPage.PICKUP_V2_RITM:
      return 'Pick-Up V2 (ritmo)';
    case AppPage.EMISSIONS_HOTELS:
      return 'Emissões (hotéis)';
    case AppPage.EMISSIONS_SEGMENTS:
      return 'Emissões (segmentos)';
    case AppPage.EMISSIONS_CHANNELS:
      return 'Emissões (canais)';
    case AppPage.RMS_FLUCTUATIONS:
      return 'Flutuações (lista)';
    case AppPage.RMS_FLUCTUATIONS_CALENDAR:
      return 'Flutuações (calendário)';
    case AppPage.RMS_FLUCTUATIONS_RULES:
      return 'Flutuações (regras)';
    case AppPage.CLEANING_MANAGEMENT:
      return 'Gestão de Limpeza';
    case AppPage.UNKNOWN:
      return 'Desconhecido (não mapeada)';
    case AppPage.GOALS:
      return 'Metas Check-in (setup)';
    case AppPage.GRI:
      return 'Relatório GRI';
    default:
      return page;
  }
}

/**
 * @description Mapeamento de rotas para o enum correspondente
 */
export function getAppPageFromPath(path: string): AppPage {
  switch (path) {
    case '/':
      return AppPage.HOME;
    case '/v2':
      return AppPage.HOME_V2;
    case '/dashboards/ranking':
      return AppPage.RANKING;
    case '/dashboards/concurrency':
      return AppPage.CONCURRENCY;
    case '/dashboards/benchmark':
      return AppPage.BENCHMARK;
    case '/dashboards/pick-ups/hotels':
      return AppPage.PICKUP_HOTELS;
    case '/dashboards/pick-ups/segments':
      return AppPage.PICKUP_SEGMENTS;
    case '/dashboards/pick-ups/channels':
      return AppPage.PICKUP_CHANNELS;
    case '/dashboards/pick-ups-v2/default':
      return AppPage.PICKUP_V2;
    case '/dashboards/pick-ups-v2/ritm':
      return AppPage.PICKUP_V2_RITM;
    case '/dashboards/emissions/hotels':
      return AppPage.EMISSIONS_HOTELS;
    case '/dashboards/emissions/segments':
      return AppPage.EMISSIONS_SEGMENTS;
    case '/dashboards/emissions/channels':
      return AppPage.EMISSIONS_CHANNELS;
    case '/rms/fluctuations':
      return AppPage.RMS_FLUCTUATIONS;
    case '/rms/fluctuations-calendar':
      return AppPage.RMS_FLUCTUATIONS_CALENDAR;
    case '/rms/fluctuation-rules':
      return AppPage.RMS_FLUCTUATIONS_RULES;
    case '/cleaning-management':
      return AppPage.CLEANING_MANAGEMENT;
    case '/goals':
      return AppPage.GOALS;
    case '/rms/gri':
      return AppPage.GRI;
    default:
      return AppPage.UNKNOWN;
  }
}

export function getPathFromAppPage(page: AppPage): string {
  switch (page) {
    case AppPage.HOME_V2:
      return '/v2';
    case AppPage.RANKING:
      return '/dashboards/ranking';
    case AppPage.CONCURRENCY:
      return '/dashboards/concurrency';
    case AppPage.BENCHMARK:
      return '/dashboards/benchmark';
    case AppPage.PICKUP_HOTELS:
      return '/dashboards/pick-ups/hotels';
    case AppPage.PICKUP_SEGMENTS:
      return '/dashboards/pick-ups/segments';
    case AppPage.PICKUP_CHANNELS:
      return '/dashboards/pick-ups/channels';
    case AppPage.PICKUP_V2:
      return '/dashboards/pick-ups-v2/default';
    case AppPage.PICKUP_V2_RITM:
      return '/dashboards/pick-ups-v2/ritm';
    case AppPage.EMISSIONS_HOTELS:
      return '/dashboards/emissions/hotels';
    case AppPage.EMISSIONS_SEGMENTS:
      return '/dashboards/emissions/segments';
    case AppPage.EMISSIONS_CHANNELS:
      return '/dashboards/emissions/channels';
    case AppPage.RMS_FLUCTUATIONS:
      return '/rms/fluctuations';
    case AppPage.RMS_FLUCTUATIONS_CALENDAR:
      return '/rms/fluctuations-calendar';
    case AppPage.RMS_FLUCTUATIONS_RULES:
      return '/rms/fluctuation-rules';
    case AppPage.CLEANING_MANAGEMENT:
      return '/cleaning-management';
    case AppPage.GOALS:
      return '/goals';
    case AppPage.GRI:
      return '/rms/gri';
    case AppPage.HOME:
    default:
      return '/';
  }
}

export const allAppPages: GenericIdName[] = Object.values(AppPage).map((x) => ({
  _id: x,
  name: getAppPageHumanRead(x),
}));
