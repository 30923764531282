import { GenericIdName } from '@/types/models/generic-id-name';

export enum FluctuationConfigOperation {
  CUSTOM_PRICE = 'CUSTOM_PRICE',
  // CUSTOM_BAR = 'CUSTOM_BAR', // TODO:
  DISABLE_ALL_CUSTOM_VALUES = 'DISABLE_ALL_CUSTOM_VALUES',
}

export function getFluctuationConfigOperationHumanRead(operation: FluctuationConfigOperation) {
  switch (operation) {
    case FluctuationConfigOperation.CUSTOM_PRICE:
      return 'Aplicar valores personalizados';
    // case FluctuationConfigOperation.CUSTOM_BAR:
    //   return 'Aplicar BAR personalizada';
    case FluctuationConfigOperation.DISABLE_ALL_CUSTOM_VALUES:
      return 'Remover todos os valores personalizados (usar somente sugestões automáticas)';
    default:
      return operation;
  }
}

export const allFluctuationConfigOperations: GenericIdName[] = Object.values(
  FluctuationConfigOperation,
).map((x) => ({ _id: x, name: getFluctuationConfigOperationHumanRead(x) }));
