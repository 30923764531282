import { formatDate, getDatesArr } from '@/utils/dates';
import { addDays, subDays } from 'date-fns';
import { GenericIdName } from '../models/generic-id-name';

export enum PickUpJobOperation {
  AVERAGE = 'AVERAGE',
  COPY_FROM_PREV = 'COPY_FROM_PREV',
  COPY_FROM_NEXT = 'COPY_FROM_NEXT',
  S3_IMPORT = 'S3_IMPORT',
}

export function getPickUpJobOperationHumanRead(status: PickUpJobOperation): string {
  switch (status) {
    case PickUpJobOperation.S3_IMPORT:
      return 'Importar do S3 (restauração do backup)';
    case PickUpJobOperation.COPY_FROM_PREV:
      return 'Copiar dia anterior (erro HITS)';
    case PickUpJobOperation.COPY_FROM_NEXT:
      return 'Copiar dia seguinte (erro HITS)';
    case PickUpJobOperation.AVERAGE:
      return 'Aplicar saldo médio (erro HITS)';
    default:
      return status;
  }
}

export const allPickUpJobOperationDropdownOptions: GenericIdName[] = Object.values(
  PickUpJobOperation,
).map((x) => ({
  _id: x,
  name: getPickUpJobOperationHumanRead(x),
}));

export function getPickUpJobOperationHumanReadDescription(
  status: PickUpJobOperation,
  startDate: Date,
  endDate: Date,
) {
  const startDateBefore = subDays(startDate, 1);
  const startDateAfter = addDays(startDate, 1);
  const endDateAfter = addDays(endDate, 1);
  const dates = getDatesArr(startDate, endDate);

  switch (status) {
    case PickUpJobOperation.S3_IMPORT:
      return `Faz download dos dados salvos no S3 (backup) e importa para o banco de dados.`;
    case PickUpJobOperation.COPY_FROM_PREV:
      return `Duplica o valor do dia anterior. Evolução nesse dia será zerada. Exemplo: a data ${formatDate(
        startDate,
        'dd/MM/yyyy',
      )} terá a mesma receita absoluta de ${formatDate(startDateBefore, 'dd/MM/yyyy')}.`;
    case PickUpJobOperation.COPY_FROM_NEXT:
      return `Duplica o valor do dia seguinte. Evolução nesse dia será zerada. Exemplo: a data ${formatDate(
        startDate,
        'dd/MM/yyyy',
      )} terá a mesma receita absoluta de ${formatDate(startDateAfter, 'dd/MM/yyyy')}.`;
    case PickUpJobOperation.AVERAGE:
      return `Aplica o saldo médio entre os intervalos. Exemplo: valor absoluto do dia ${formatDate(
        endDateAfter,
        'dd/MM/yyyy',
      )} mais o valor absoluto do dia ${formatDate(startDateBefore, 'dd/MM/yyyy')} dividido por ${
        dates.length
      }.`;
    default:
      return status;
  }
}
