import FormProvider from '@/components/forms/hook-form/FormProvider';
import Iconify from '@/components/Iconify';
import { useSession } from '@/hooks/auth/useSession';
import useAppliedFilters from '@/hooks/useAppliedFilters';
import { useNotification } from '@/hooks/useNotification';
import { useAppDispatch, useAppSelector } from '@/redux';
import { updateUserSession } from '@/redux/slices/authSlice';
import { applySavedSearchFilters, toggleSavedSearchModalOpened } from '@/redux/slices/filtersSlice';
import { ApiV1UpdateUserSessionRequest } from '@/types/apiv1/requests/api-v1-update-user-session-request';
import {
  getAppPageFromPath,
  getAppPageHumanRead,
  getPathFromAppPage,
} from '@/types/enums/app-page.enum';
import { apiV1CrudsService } from '@/utils/api';
import { hasPermissionToEditSavedSearch } from '@/utils/saved-search';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tab,
  Tabs,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import SavedSearchModalForm from './Form';
import PermissionsTable from './PermissionsTable';

type FormValuesProps = {
  name: string;
  description: string;
  users: string[];
  wallets: string[];
};

const Schema = Yup.object().shape({
  name: Yup.string().required('Campo obrigatório'),
});

export default function SavedSearchModal() {
  const [activeTab, setActiveTab] = useState('general');
  const { showSuccess, showInfo } = useNotification();
  const { modalOpened, savedSearch } = useAppSelector((state) => state.filters.savedSearch);
  const session = useSession();

  const isEdit = !!savedSearch?._id;
  const page = !!savedSearch?.page
    ? savedSearch?.page
    : getAppPageFromPath(window.location.pathname);
  const pageTitle = getAppPageHumanRead(savedSearch?.page ?? page);
  const appliedFilters = useAppliedFilters(page);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(Schema),
    defaultValues: {
      name: '',
      description: '',
      wallets: [],
      users: [],
    },
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
    setValue,
    reset,
  } = methods;

  const onClose = () => {
    dispatch(toggleSavedSearchModalOpened({ savedSearch: null, modalOpened: false }));
    setActiveTab('general');
    reset();
  };

  useEffect(() => {
    if (!!savedSearch?._id) {
      setValue('name', savedSearch.name);
      setValue('description', savedSearch.description || '');
      setValue(
        'users',
        savedSearch.users.map((user: any) => user._id),
      );
      setValue(
        'wallets',
        savedSearch.wallets.map((wallet: any) => wallet._id),
      );
    }
  }, [savedSearch, setValue]);

  const onSubmit = async (values: FormValuesProps) => {
    try {
      if (isEdit) {
        await apiV1CrudsService.put(`/saved-searches/${savedSearch._id}`, values);
        showSuccess('Dados atualizados com sucesso!');
      } else {
        await apiV1CrudsService.post(`/saved-searches`, { ...values, page, appliedFilters });
        showSuccess('Filtro criado com sucesso!');
      }
      onClose();
    } catch {}
  };

  const onEditFilters = async () => {
    if (!savedSearch) return;
    navigate(getPathFromAppPage(savedSearch.page));
    dispatch(toggleSavedSearchModalOpened({ savedSearch, modalOpened: false }));
    dispatch(applySavedSearchFilters(savedSearch));
    showInfo('Altere os filtros e clique em "Salvar" para atualizar a busca personalizada.');
    const useSessionParams: ApiV1UpdateUserSessionRequest = {
      mode: savedSearch?.userSession?.mode,
      hotels: savedSearch?.userSession?.hotels.map((x) => x._id),
      regionals: savedSearch?.userSession?.regionals.map((x) => x._id),
      cities: savedSearch?.userSession?.cities.map((x) => x._id),
      wallets: savedSearch?.userSession?.wallets.map((x) => x._id),
    };
    await dispatch(updateUserSession(useSessionParams));
    reset();
  };

  const showPermissionsTable = !!savedSearch?._id
    ? hasPermissionToEditSavedSearch(session, savedSearch)
    : false;

  return (
    <Dialog open={modalOpened} onClose={onClose} fullWidth maxWidth="lg">
      <DialogTitle id="alert-dialog-title" sx={{ borderBottomStyle: 'dashed', pb: 2 }}>
        {isEdit ? `Alterar filtros: ${savedSearch?.name}` : 'Salvar filtros'} - {pageTitle}
      </DialogTitle>

      <DialogContent>
        <Tabs
          allowScrollButtonsMobile
          variant="scrollable"
          scrollButtons="auto"
          value={activeTab}
          onChange={(e, val) => setActiveTab(val)}
        >
          <Tab
            disableRipple
            value="general"
            label="Geral"
            icon={<Iconify icon={'ic:round-account-box'} width={20} height={20} />}
          />
          <Tab
            disableRipple
            disabled={!showPermissionsTable}
            value="permissions"
            label="Permissões"
            icon={<Iconify icon="ic:round-lock" width={20} height={20} />}
          />
        </Tabs>

        {activeTab === 'general' ? (
          <>
            <DialogContentText sx={{ my: 3 }}>
              Insira as informações abaixo para salvar os filtros de busca e compartilhar com outros
              usuários.
            </DialogContentText>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
              <SavedSearchModalForm />
            </FormProvider>
          </>
        ) : (
          <PermissionsTable />
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Fechar
        </Button>
        {activeTab === 'general' ? (
          <>
            {isEdit ? (
              <Button variant="text" onClick={onEditFilters}>
                Editar filtros na {pageTitle}
              </Button>
            ) : null}
            <LoadingButton
              variant="contained"
              loading={isSubmitting}
              onClick={handleSubmit(onSubmit)}
            >
              {isEdit ? 'Salvar alterações' : 'Criar filtro'}
            </LoadingButton>
          </>
        ) : null}
      </DialogActions>
    </Dialog>
  );
}
