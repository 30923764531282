import RHFSelectStaticMultiple from '@/components/forms/hook-form/RHFSelectStaticMultiple';
import RHFTextField from '@/components/forms/hook-form/RHFTextField';
import { useFetchList } from '@/hooks/useFetchList';
import { GenericIdName } from '@/types/models/generic-id-name';
import { Grid } from '@mui/material';

export default function SavedSearchModalForm() {
  const { response: users, loading: loadingUsers } = useFetchList<GenericIdName>(
    '/drop-down-lists?modelName=User',
  );
  const { response: wallets, loading: loadingWallets } = useFetchList<GenericIdName>(
    '/drop-down-lists?modelName=Wallet',
  );

  return (
    <Grid container spacing={3} sx={{ mb: 2 }}>
      <Grid item xs={12} md={6}>
        <RHFTextField
          placeholder="Exemplo: Faturamento anual por hotel"
          autoFocus
          size="small"
          name="name"
          label="Nome"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <RHFTextField
          size="small"
          placeholder="Campo opcional"
          name="description"
          label="Descrição"
        />
      </Grid>

      <Grid item xs={12} md={12}>
        <RHFSelectStaticMultiple
          label="Compartilhar com usuários"
          size="small"
          loading={loadingUsers}
          name="users"
          options={users}
        />
      </Grid>

      <Grid item xs={12} md={12}>
        <RHFSelectStaticMultiple
          size="small"
          label="Compartilhar com carteiras"
          loading={loadingWallets}
          name="wallets"
          options={wallets}
        />
      </Grid>
    </Grid>
  );
}
