import Label from '@/components/Label';
import { useAppSelector } from '@/redux';
import {
  FluctuationConfigOperation,
  getFluctuationConfigOperationHumanRead,
} from '@/types/enums/rms/fluctuation-config-operation.enum';
import { FluctuationType } from '@/types/enums/rms/fluctuation-type.enum';
import { daysOfWeek, formatDate } from '@/utils/dates';
import { formatCurrency, formatPercentage } from '@/utils/number';
import { getRmsFieldNameHumanReadable } from '@/utils/rms/fluctuation';
import { Grid, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { FluctuationConfigFormValuesProps } from '.';
import { FluctuationConfigTable } from './FluctuationConfigTable';

export default function FluctuationConfigModalStep3() {
  const { selectedRatePlans, selectedRoomTypes, selectedDates, selectedFluctuations } =
    useAppSelector((state) => state.rms.fluctuationConfigOptions);

  const { watch } = useFormContext<FluctuationConfigFormValuesProps>();
  const values = watch();

  return (
    <Grid container spacing={1} sx={{ mb: 5 }} alignItems="center">
      {values.operation === FluctuationConfigOperation.CUSTOM_PRICE ? (
        <Grid xs={12} md={12} item>
          <Typography variant="body2">
            <b>Operação a ser realizada:</b> Acréscimo de{' '}
            {values.additionalPriceType === FluctuationType.FIXED
              ? formatCurrency(values.additionalPrice)
              : formatPercentage(values.additionalPrice)}{' '}
            em cima de cada <b>{getRmsFieldNameHumanReadable(values.additionalFieldRef)}</b>.
          </Typography>
        </Grid>
      ) : (
        <Grid xs={12} md={12} item>
          <Typography variant="body2">
            <b>Operação a ser realizada:</b>{' '}
            {getFluctuationConfigOperationHumanRead(values.operation)}
          </Typography>
        </Grid>
      )}
      <Grid item xs={12}>
        <Typography variant="body2">
          <b>Planos tarifários:</b>
        </Typography>
      </Grid>
      {selectedRatePlans.map((ratePlan) => (
        <Grid key={ratePlan._id} item xs={2}>
          <Label color="error" sx={{ ml: 1 }}>
            {ratePlan.name}
          </Label>
        </Grid>
      ))}
      <Grid item xs={12}>
        <Typography variant="body2">
          <b>Acomodações:</b>
        </Typography>
      </Grid>
      {selectedRoomTypes.map((roomType) => (
        <Grid key={roomType._id} item xs={2}>
          <Label color="default" sx={{ ml: 1 }}>
            {roomType.name}
          </Label>
        </Grid>
      ))}
      <Grid item xs={12}>
        <Typography variant="body2">
          <b>Dias da semana:</b>
        </Typography>
      </Grid>
      {values.weekDays.map((day) => (
        <Grid key={day} item xs={1}>
          <Label color="default" sx={{ ml: 1 }}>
            {daysOfWeek.find((x) => x._id === day)?.name}
          </Label>
        </Grid>
      ))}

      {values.operation === FluctuationConfigOperation.DISABLE_ALL_CUSTOM_VALUES && (
        <>
          <Grid item xs={12}>
            <Typography variant="body2">
              <b>Datas:</b>
            </Typography>
          </Grid>
          {selectedDates
            .filter((x) => values.weekDays.includes(x.getDay().toString()))
            .map((date, i) => (
              <Grid key={i} item xs={2}>
                <Label color="default" sx={{ ml: 1 }}>
                  {formatDate(date, 'dd/MM/yyyy (EEEEEE)')}
                </Label>
              </Grid>
            ))}
        </>
      )}

      {selectedFluctuations.length > 0 && (
        <Grid item xs={12} sx={{ mt: 3 }}>
          <FluctuationConfigTable />
        </Grid>
      )}
    </Grid>
  );
}
