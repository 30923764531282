import { LabelColor } from '@/components/Label';
import { GenericIdName } from '@/types/models/generic-id-name';

export enum RmsActionLogOperation {
  CREATED = 'CREATED',
  UPDATED = 'UPDATED',
  DELETED = 'DELETED',
  USER_DECISION = 'USER_DECISION',
}

export function getRmsActionLogOperationHumanReadable(operation: RmsActionLogOperation): string {
  switch (operation) {
    case RmsActionLogOperation.CREATED:
      return 'Item criado';
    case RmsActionLogOperation.UPDATED:
      return 'Item atualizado';
    case RmsActionLogOperation.DELETED:
      return 'Item deletado';
    case RmsActionLogOperation.USER_DECISION:
      return 'Decisão do usuário';
    default:
      return operation;
  }
}

export function getRmsActionLogOperationColor(operation: RmsActionLogOperation): LabelColor {
  switch (operation) {
    case RmsActionLogOperation.CREATED:
      return 'success';
    case RmsActionLogOperation.UPDATED:
      return 'warning';
    case RmsActionLogOperation.DELETED:
      return 'error';
    case RmsActionLogOperation.USER_DECISION:
      return 'info';
    default:
      return 'default';
  }
}

export const allRmsActionLogOperations: GenericIdName[] = Object.values(RmsActionLogOperation).map(
  (x) => ({
    _id: x,
    name: getRmsActionLogOperationHumanReadable(x),
  })
);
