import { RMS_ICON } from '@/config/icons';
import {
  FluctuationStatus,
  getFluctuationsStatusDescriptionHumanRead,
  getFluctuationsStatusHumanRead,
} from '@/types/enums/rms/fluctuation-status.enum';
import { FluctuationType } from '@/types/enums/rms/fluctuation-type.enum';
import { Fluctuation } from '@/types/models/fluctuation';
import { FluctuationRule, FluctuationRulePeriod } from '@/types/models/fluctuation-rule';
import { FluctuationRuleBar } from '@/types/models/fluctuation-rule-bar';
import { formatDate } from '../dates';
import { formatCurrency, formatPercentage } from '../number';

export * from './gri';

export function getFluctuationRuleCardTitle(rule: FluctuationRule) {
  return `${rule.name} (${rule.active ? 'Ativa' : 'Inativa'})`;
}

export function getFluctuationRuleCardIcon(rule: FluctuationRule) {
  if (rule.active) {
    return 'flat-color-icons:ok';
  }
  return 'flat-color-icons:cancel';
}

export function getFluctuationRuleCardIconTooltip(rule: FluctuationRule) {
  if (rule.active) {
    return 'Ativa';
  }
  return 'Inativa';
}

export function getFluctuationRulePeriodRangeHumanRead(period: FluctuationRulePeriod) {
  const { startDate, endDate } = period;
  return `${formatDate(startDate, 'dd/MM/yyyy')} a ${formatDate(endDate, 'dd/MM/yyyy')}`;
}

export function getFluctuationRuleBarOccRangeHumanRead(bar: FluctuationRuleBar) {
  const { occupationStartRange, occupationEndRange } = bar;
  return `${formatPercentage(occupationStartRange, 0)} a ${formatPercentage(
    occupationEndRange,
    0,
  )}`;
}

export function getFluctuationRuleBarChartLabel(bar: FluctuationRuleBar) {
  const { name, variation, variationType, occupationEndRange, occupationStartRange } = bar;
  const delta = occupationEndRange - occupationStartRange;
  if (delta <= 5) {
    return name;
  }
  if (delta <= 10) {
    return getFluctuationRuleBarOccRangeHumanRead(bar);
  }
  return `${getFluctuationRuleBarOccRangeHumanRead(bar)} (${getFlutuationTypeHumanRead(
    variation,
    variationType,
  )})`;
}

export function getFlutuationTypeHumanRead(value: number, type: FluctuationType) {
  const sign = value > 0 ? '+' : '';
  if (type === FluctuationType.FIXED) {
    return `${sign}${formatCurrency(value)}`;
  }
  return `${sign}${formatPercentage(value)}`;
}

export function getFluctuationDetailsCardIcon(fluctuation: Fluctuation): string {
  switch (fluctuation.status) {
    case FluctuationStatus.QUEUED:
      return 'ph:clock-bold';
    case FluctuationStatus.DECISION_PENDING:
      return 'fluent:thumb-like-dislike-16-regular';
    case FluctuationStatus.DISABLED:
      return 'ic:baseline-cancel';
    case FluctuationStatus.ERROR:
    case FluctuationStatus.OMNIBEES_DISABLED:
    case FluctuationStatus.OMNIBEES_RATE_PLAN_UNLINKED:
    case FluctuationStatus.OMNIBEES_ROOM_TYPE_UNLINKED:
      return 'ic:baseline-error';
    case FluctuationStatus.UPDATED:
      return 'fluent:checkmark-24-regular';
    default:
      return RMS_ICON;
  }
}

export function getFluctuationDetailsCardTitle(fluctuation: Fluctuation): string {
  switch (fluctuation.status) {
    case FluctuationStatus.UPDATED:
      return 'Sugestão aplicada';
    case FluctuationStatus.QUEUED:
      return 'Na fila de processamento';
    case FluctuationStatus.DECISION_PENDING:
      return 'Tomada de Decisão';
    case FluctuationStatus.DISABLED:
      return 'Sugestões desativadas permanentemente';
    case FluctuationStatus.ERROR:
      return 'Erro de integração';
    case FluctuationStatus.OMNIBEES_DISABLED:
      return 'OmniBees desativada';
    case FluctuationStatus.OMNIBEES_RATE_PLAN_UNLINKED:
      return 'Plano tarifário desvinculado';
    case FluctuationStatus.OMNIBEES_ROOM_TYPE_UNLINKED:
      return 'Acomodação desvinculada';
    case FluctuationStatus.OMNIBEES_INCOMPATIBLE_ROOM_OR_RATE:
      return getFluctuationsStatusHumanRead(fluctuation.status);
    case FluctuationStatus.EXPIRED:
      return 'Sugestão expirada';
    case FluctuationStatus.BAR_NOT_FOUND:
      return 'Nenhuma BAR encontrada';
    case FluctuationStatus.RMS_DISABLED:
      return 'RMS desativado';
    default:
      return fluctuation.status;
  }
}
export function getFluctuationDetailsCardSubTitle(fluctuation: Fluctuation): string {
  switch (fluctuation.status) {
    case FluctuationStatus.UPDATED:
      return `Sugestão de tarifa ${formatCurrency(
        fluctuation.appliedPrice,
      )} aplicada com sucesso na Omnibees.`;
    case FluctuationStatus.QUEUED:
      return `Aguardando execução do robô para integração com Omnibees.`;
    case FluctuationStatus.DECISION_PENDING:
      return `Status atual: ${getFluctuationsStatusHumanRead(fluctuation.status)}`;
    case FluctuationStatus.DISABLED:
      return 'Todas as sugestões foram desativadas para esta data.';
    case FluctuationStatus.ERROR:
      return 'Erro de integração com a Omnibees.';
    case FluctuationStatus.OMNIBEES_DISABLED:
      return 'Integração Omnibees desativada.';
    case FluctuationStatus.OMNIBEES_RATE_PLAN_UNLINKED:
      return 'Plano tarifário desvinculado da Omnibees.';
    case FluctuationStatus.OMNIBEES_ROOM_TYPE_UNLINKED:
      return 'Acomodação desvinculada da Omnibees.';
    case FluctuationStatus.OMNIBEES_INCOMPATIBLE_ROOM_OR_RATE:
      return getFluctuationsStatusDescriptionHumanRead(fluctuation);
    case FluctuationStatus.EXPIRED:
      return `Sugestão de tarifa com data passada e expirada.`;
    case FluctuationStatus.BAR_NOT_FOUND:
      return 'Nenhuma BAR encontrada';
    case FluctuationStatus.RMS_DISABLED:
      return 'RMS desativado';
    default:
      return fluctuation.status;
  }
}

export function getRmsActionLogModelNameHumanReadable(modelName: string) {
  switch (modelName) {
    case 'RoomType':
      return 'Acomodação';
    case 'RoomTypeCategory':
      return 'Categoria de Acomod.';
    case 'RatePlan':
      return 'Plano tarif.';
    case 'FluctuationRule':
      return 'Regra de flut.';
    case 'FluctuationRuleBar':
      return 'Bar';
    case 'FluctuationPrice':
      return 'Tarifa pers.';
    case 'Fluctuation':
      return 'Flutuação';
    default:
      return modelName;
  }
}

export function getRmsFieldNameHumanReadable(fieldName: string) {
  switch (fieldName) {
    case 'name':
      return 'Nome';
    case 'minPrice':
      return 'Valor mín.';
    case 'active':
      return 'Ativo';
    case 'extraBedPriceAdult':
      return 'Cama extra adulto';
    case 'extraBedPriceChild':
      return 'Cama extra criança';
    case 'omnibeesRoomType':
      return 'Acomod. Omnibees';
    case 'variation':
      return 'Variação';
    case 'isHotelDefault':
      return 'Padrão do hotel';
    case 'isRoomTypeDefault':
      return 'Padrão da acomodação';
    case 'recurrence':
      return 'Recorrência';
    case 'basePrice':
      return 'Tarifa base';
    case 'omnibeesRate':
      return 'Tarifa Omnibees';
    case 'description':
      return 'Descrição';
    case 'period':
      return 'Período';
    case 'color':
      return 'Cor';
    case 'occupationStartRange':
      return 'Ocupação inicial';
    case 'occupationEndRange':
      return 'Ocupação final';
    case 'usingCustomPrice':
      return 'Valor personalizado habilitado';
    case 'customPrice':
      return 'Valor personalizado';
    case 'status':
      return 'Status';
    case 'omnibeesConfig.integrationEnabled':
      return 'Flag Integração Omnibees';
    case 'omnibeesConfig.hotelId':
      return 'ID hotel Omnibees';
    case 'omnibeesConfig.token':
      return 'Token Omnibees';
    case 'additionalPrice':
      return 'Valor adicional';
    case 'hasAdditionalPrice':
      return 'Flag valor adicional';
    case 'additionalPriceType':
      return 'Tipo de valor adicional';
    case 'suggestedPrice':
      return 'Valor sugerido';
    case 'appliedPrice':
      return 'Último valor aplicado';
    case 'rmsMode':
      return 'Modo RMS';
    case 'rmsFutureMonths':
      return 'Meses a processar';
    default:
      return fieldName;
  }
}

export function getRmsVariation(
  price: number,
  variation: number,
  variationType: FluctuationType,
): number {
  if (variationType === FluctuationType.PERCENTAGE) {
    return +(price * (1 + variation / 100)).toFixed(2);
  }
  return +(price + variation).toFixed(2);
}
