import { FluctuationType } from '../enums/rms/fluctuation-type.enum';
import { Hotel } from './hotel';
import { RoomType } from './room-type';

export type RoomTypeCategory = {
  _id: string;
  occupancy: number; // SGL, DBL, TPL, QPL, QNT (1, 2, 3, 4, 5)
  variation: number;
  active: boolean;
  variationType: FluctuationType;
  hotel: Partial<Hotel>;
  recurrence: RoomTypeCategory;
  roomType: RoomType;
  isHotelDefault: boolean;
  isRoomTypeDefault: boolean;
};

export function getRoomTypeCategoryOccupancyString(occupancy: number) {
  switch (occupancy) {
    case 1:
      return 'SGL';
    case 2:
      return 'DBL';
    case 3:
      return 'TPL';
    case 4:
      return 'QDP';
    case 5:
      return 'QTP';
    case 6:
      return 'SEX';
    case 7:
      return 'SET';
    case 8:
      return 'OCT';
    case 9:
      return 'NON';
    case 10:
      return 'DEC';
    default:
      return occupancy?.toString();
  }
}
