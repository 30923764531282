import DrawersToggleButton from './DrawersToggleButton';
import SavedSearchesDrawer from './saved-searches-drawer';
import ThemeDrawer from './theme-drawer';

export default function GlobalDrawers() {
  return (
    <>
      <DrawersToggleButton />
      <SavedSearchesDrawer />
      <ThemeDrawer />
    </>
  );
}
