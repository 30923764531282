import RHFSelectStatic from '@/components/forms/hook-form/RHFSelectStatic';
import RHFSelectStaticMultiple from '@/components/forms/hook-form/RHFSelectStaticMultiple';
import RHFTextField from '@/components/forms/hook-form/RHFTextField';
import { useAppSelector } from '@/redux';
import { FluctuationConfigOperation } from '@/types/enums/rms/fluctuation-config-operation.enum';
import { FluctuationType, allFluctuationTypes } from '@/types/enums/rms/fluctuation-type.enum';
import { daysOfWeek } from '@/utils/dates';
import { getRmsFieldNameHumanReadable } from '@/utils/rms/fluctuation';
import { Grid } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { FluctuationConfigFormValuesProps } from '.';
import { FluctuationConfigTable } from './FluctuationConfigTable';

export default function FluctuationConfigModalStep2() {
  const { selectedDates, selectedFluctuations } = useAppSelector(
    (state) => state.rms.fluctuationConfigOptions,
  );
  const { watch } = useFormContext<FluctuationConfigFormValuesProps>();
  const values = watch();

  return (
    <Grid container spacing={4} sx={{ mb: 5 }}>
      {values.operation === FluctuationConfigOperation.CUSTOM_PRICE ? (
        <>
          <Grid item xs={12} md={6}>
            <RHFSelectStatic
              disableClearable
              size="small"
              name="additionalFieldRef"
              label="Operação"
              options={['absolute', 'suggestedPrice', 'appliedPrice', 'customPrice'].map((x) => ({
                _id: x,
                name:
                  x === 'absolute'
                    ? 'Estipular um valor fixo'
                    : `Aplicar acréscimo em: ${getRmsFieldNameHumanReadable(x)}`,
              }))}
            />
          </Grid>
          <Grid item xs={12} md={values.additionalFieldRef === 'absolute' ? 6 : 3}>
            <RHFTextField
              size="small"
              name="additionalPrice"
              prefix={values.additionalPriceType === FluctuationType.FIXED ? 'R$' : ''}
              suffix={values.additionalPriceType === FluctuationType.PERCENTAGE ? '%' : ''}
              type="number"
              label={
                values.additionalFieldRef === 'absolute'
                  ? 'Valor Fixo'
                  : values.additionalPriceType === FluctuationType.PERCENTAGE
                  ? 'Porcentagem Adicional'
                  : 'Valor adicional'
              }
              autoFocus
            />
          </Grid>
          {values.additionalFieldRef === 'absolute' ? null : (
            <Grid item xs={12} md={3}>
              <RHFSelectStatic
                disableClearable
                size="small"
                name="additionalPriceType"
                label="Tipo do acréscimo"
                options={allFluctuationTypes}
              />
            </Grid>
          )}
        </>
      ) : null}
      <Grid item xs={12} md={12}>
        <RHFSelectStaticMultiple
          disabled={selectedDates.length <= 1}
          size="small"
          label="Dias da semana"
          name="weekDays"
          helperText="Selecione apenas os dias da semana onde essa alteração deverá ser aplicada."
          options={daysOfWeek}
        />
      </Grid>

      {selectedFluctuations.length > 0 && (
        <Grid item xs={12}>
          <FluctuationConfigTable />
        </Grid>
      )}
    </Grid>
  );
}
